

























































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import AnyMessageFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/modules/modules/AnyMessageFilterHandlerModule/AnyMessageFilterHandlerModuleBuilder";
import {
  MessageHandlerTypesEnum
} from "@/includes/logic/Modules/modules/modules/AnyMessageFilterHandlerModule/AnyMessageFilterHandlerModule";
import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../../components/filters/layout/FilterSettingLayout.vue";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SelectInput,
    ExtendedHelpMessage
  },
})
export default class AnyMessageFilterHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: AnyMessageFilterHandlerModuleBuilder

  anyMessageHandlerTypeOptions: Array<SelectOption> = [
    {
      label: this.$t("any_message_handler_type_any").toString(),
      value: MessageHandlerTypesEnum.Any,
    },
    {
      label: this.$t("any_message_handler_type_text_caption").toString(),
      value: MessageHandlerTypesEnum.TextCaption,
    },
    {
      label: this.$t("any_message_handler_type_text").toString(),
      value: MessageHandlerTypesEnum.Text,
    },
  ]
}
